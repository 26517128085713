/* eslint-disable react-hooks/exhaustive-deps */
// import React from 'react';
import { useEffect, useRef } from 'react';
import { Player, Parser } from 'svgaplayerweb';

const SVGAPlayer = ({ src, width, height, onClose, currentIndexRef }) => {
  const playerRef = useRef(null);
  let player: Player;
  let parser: Parser;
  let number: number = 0
  useEffect(() => {
    if (playerRef.current) {
      if (!player || !parser) {
        player = new Player(playerRef.current);
        parser = new Parser();

        // 加载 SVGA 文件
        player.onFinished(() => {
          onClose()
        })
        player.onPercentage((percentage) => {
          if (percentage > 0.99) {
            number += 1
            if (number == 3) {
              player.stopAnimation(true)
            }
          }
        })
        parser.load(
          src,
          videoItem => {
            player.setVideoItem(videoItem); // 设置视频项
            player.setContentMode('AspectFit');
            player.startAnimation(); // 开始播放动画
          },
          error => {
            console.log(error);
          },
          number = 0,
        );
      }
    }
  }, [currentIndexRef]);

  return <div ref={playerRef} style={{ width: width, height: height }}></div>;
};

export default SVGAPlayer;
