import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

interface HttpParams {
  url: string;
  method: "get" | "post" | "put" | "delete" | "patch";
  data?: any;
  responseType?: any;
  params?: Record<string, any>;
}

const instance: AxiosInstance = axios.create({
  baseURL: "https://api.daka.biz/ad", // 设置基础URL_正式
  // baseURL: "https://dev.daka.biz/ad", // 设置基础URL_dev
  timeout: 10000, // 设置请求超时时间
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    // 在发送请求之前做些什么
    return config;
  },
  (error: any) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // 对响应数据做点什么
    const resp = response.data;
    console.log(resp, "-----响应数据");
    if (resp.code == 200) {
      return resp;
    } else
      return {
        error: {
          code: resp.error?.code,
          message: resp.error?.message || "服务器繁忙!",
        },
      };
  },
  (error: any) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default class HttpUtils {
  public static async request<T>(params: HttpParams): Promise<T> {
    const response = await instance.request<T>(params);
    return response as T;
  }

  public static async get<T>(
    url: string,
    params?: Record<string, any>
  ): Promise<T> {
    return this.request({ url, method: "get", data: params });
  }

  public static async post<T>(url: string, data?: any): Promise<T> {
    return this.request({ url, method: "post", data });
  }

  public static async put<T>(url: string, data?: any): Promise<T> {
    return this.request({ url, method: "put", data });
  }

  public static async delete<T>(url: string, data?: any): Promise<T> {
    return this.request({ url, method: "delete", data });
  }
}
