import "./App.css";

import Screen from "./screen"; // 确保路径正确

function App() {
  return (
    <div className="App">
      <Screen />
    </div>
  );
}

export default App;
