import HttpUtils from "./http";

const screenApi = {
  async getPageReward(data: any) {
    try {
      const res = await HttpUtils.get<{ data: any }>(
        `/order/pageReward?storeID=${data.storeID}&page=${data.page}&size=${data.size}`
      );
      return res;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
};
export default screenApi;
