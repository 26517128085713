import { useState, useEffect } from 'react';
import { Img } from '../../imgRequest'
// import React from 'react';
import './barrage.css'; // 引入 CSS 样式文件


const Barrage = ({ items }) => {
  const [barrages, setBarrages] = useState<any>([]);

  useEffect(() => {
    // 更新弹幕位置
    const interval = setInterval(() => {
      setBarrages((prevBarrages: any[]) => {
        return prevBarrages.map(barrage => {
          // 检查是否超出屏幕左侧，超出则回到屏幕右侧重新开始
          if (parseFloat(barrage.left) < -110) {
            return {
              ...barrage,
              left: '110%',
              top: `${Math.random() * 80 + 15}%`,
              speed: Math.random() * 0.05 + 0.05, // 随机生成速度
            };
          }
          // 否则继续向左移动
          return {
            ...barrage,
            left: `${parseFloat(barrage.left) - barrage.speed}%`,
          };
        });
      });
    }, 10); // 每50毫秒更新一次位置

    return () => clearInterval(interval); // 组件卸载时清除定时器
  }, []); // 空依赖数组，仅在初始渲染时执行

  useEffect(() => {
    // 将新数据添加到弹幕列表中
    if (items.length > 0) {
      items.forEach((item: any, index: number) => {
        if (!barrages.some((barrage: { item: { order_no: any; }; }) => barrage.item.order_no === item.order_no)) {
          setBarrages((prevBarrages: string | any[]) => [
            ...prevBarrages,
            {
              id: prevBarrages.length + index, // 使用之前弹幕的长度来保证唯一性
              item: item,
              top: `${Math.random() * 80 + 15}%`, // 随机生成初始位置
              left: '110%', // 初始位置在屏幕右侧
              speed: Math.random() * 0.1 + 0.1, // 随机生成速度
            }
          ]);
        }
      });
    }
  }, [items, barrages]);

  return (
    <div className="barrage-container">
      {barrages && barrages.map((barrage: any, index: number) => (
        // <div key={index} className={`barrage-item ${barrage.item.amount > 20 ? 'medium' : barrage.item.random > 188 ? 'large' : ''}`} style={{ top: barrage.top, left: barrage.left }}>
        <div key={index} className={`barrage-item`} style={{ top: barrage.top, left: barrage.left }}>
          <img src={Img('rewardHorn')} />
          <div><text>{barrage.item.order_desc.split("&")[0]}</text>送来开业祝福<text>¥{barrage.item.amount}</text><text>{barrage.item.order_desc.split("&")[1]}</text></div>
          <img src={Img(`rewardSign_${barrage.item.random || '1'}`)} />
        </div>
      ))}
    </div>
  );
};

export default Barrage;
