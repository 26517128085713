import { useState, useEffect, useRef } from 'react'
import { Img } from '../imgRequest'
import screenApi from '../api'
import Svg from './components/svg';
import Barrage from './components/barrage'
// import React from 'react';

import './index.css'

const Screen = () => {
  const [showTipGiver, setShowTipGiver] = useState<boolean>(false);
  const [showTipGiverTran, setShowTipGiverTran] = useState<boolean>(false);
  const [documentWidth, setDocumentWidth] = useState<number>(document.documentElement.clientWidth);
  const [documentHeight, setDocumentHeight] = useState<number>(document.documentElement.clientHeight);
  const [rewardList, setRewardList] = useState<any[]>([]);
  const [thenReward, setThenReward] = useState<any[]>([])
  const currentIndexRef = useRef<number>(0); // 当前播放的 svga 文件索引

  useEffect(() => {
    function updateDimensions() {
      setDocumentWidth(document.documentElement.clientWidth);
      setDocumentHeight(document.documentElement.clientHeight);
    }
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const loadPageReward = async () => {
    const res: any = await screenApi.getPageReward({ storeID: paramsId, page: 1, size: 10000 })
    if (res.code === 200) {
      const result = res.data.result || []
      const data = result.map((i: any) => {
        return {
          ...i,
          random: Math.floor(Math.random() * 3) + 1
        }
      })
      setRewardList(data)
    }
  }

  const currentURL = window.location.href;
  const locUrl = new URL(currentURL);
  const paramsId = locUrl.pathname.substring(1);

  const userID = paramsId; // 示例用户ID，根据实际情况调整
  const url = 'https://event.daka.biz/events'; // 服务器地址
  // const url = 'https://dev.event.daka.biz/events'
  useEffect(() => {
    loadPageReward()
    const eventSource = new EventSource(`${url}?userid=${userID}`);
    eventSource.onmessage = (event) => {
      if (event && event.data) {
        try {
          const topic = JSON.parse(event.data)
          console.log(topic)
          if (topic.topic === 'order_reward') {
            const data = topic.data
            setRewardList(prevList => [...prevList, data])
            setThenReward(prevData => [...prevData, data]);
            setShowTipGiver(true)
            setTimeout(() => {
              setShowTipGiverTran(true)
            }, 200)
          }
          // data.random = Math.floor(Math.random() * 3) + 1

        } catch (error) {
          console.log('Received non-JSON data:', event.data);
          // 进一步处理非 JSON 数据
        }
      }
    };
    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      eventSource.close();
    };
    return () => {
      eventSource.close();
      setRewardList([])
      setThenReward([])
      currentIndexRef.current = 0
    };
  }, []);
  useEffect(() => {
    console.log(rewardList)
  }, [rewardList.length])
  const oncolseSvg = () => {
    currentIndexRef.current = currentIndexRef.current + 1;
    setShowTipGiver(false)
    setShowTipGiverTran(false)
  }
  useEffect(() => {
    if (thenReward[currentIndexRef.current]) {
      setShowTipGiver(true)
      setTimeout(() => {
        setShowTipGiverTran(true)
      }, 200)
    }
  }, [currentIndexRef.current, thenReward])
  useEffect(() => {
    let script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'https://cdn.jsdelivr.net/npm/howler@2.0.15/dist/howler.core.min.js';
    document.getElementById('root')?.appendChild(script2);
  }, []);

  const render = () => {
    return (
      <div className="page">
        <div style={{ width: documentWidth, height: documentHeight, backgroundImage: `url(${Img('rewardScreen')})` }} className='content'>
          <div className='listBox' style={{ width: documentWidth, height: documentHeight }}>
            <Barrage items={rewardList} />
          </div>
          <div className='qrCodeBox'>
            <img src={`https://daka-upload.oss-cn-hangzhou.aliyuncs.com/web/reward/reward_store${paramsId}.png`} />
            <text>微信扫码</text>
            <text>祝福上墙</text>
          </div>
          {currentIndexRef.current <= thenReward.length && thenReward[currentIndexRef.current] &&
            <div className='videobox' style={{ width: documentWidth, height: documentHeight }}>
              <Svg src={Img(`screenReward_${thenReward[currentIndexRef.current].order_title.split("&")[0]}`, 'svga')} width={documentWidth} height={documentHeight} onClose={oncolseSvg} currentIndexRef={currentIndexRef.current} />
              {showTipGiver && <div className={`tipGiver ${showTipGiverTran ? 'tipGiver_transition' : ''}`}>
                <text>{thenReward[currentIndexRef.current].order_desc.split("&")[0]}</text>
                <text>送来开业祝福：¥{thenReward[currentIndexRef.current].amount}</text>
                <text>{thenReward[currentIndexRef.current].order_desc.split("&")[1]}</text>
              </div>}
            </div>
          }
        </div>
      </div >
    )
  }
  return render()
}
export default Screen
